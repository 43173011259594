<template>
	<div class="hist">
		<div class="hist-box">
			<div class="hist-warp" v-if="!showNav">

				<div class="room-left-hint">
					<!-- <div class="roomleft-num">
						<span @click="goLucky">盲盒对战</span>
						<span>></span>
						<span>参加对战编号 {{ id }}</span>
					</div> -->
					<div class="roomleft-btn">
						<div @click="goLucky"><img src="../assets/img/ranking/zuo.png">返回</div>
						<!-- <span>邀请好友</span> -->
					</div>
				</div>

				<div class="title">
					<span class="title-text">我购买的</span>
					<span class="en-title">MYGAME</span>
				</div>
				<div class="hist-list">
					<div class="game-item" v-for="(item,index) in tableData1">
						<div class="game-header">
							<div class="game-state"><span class="end">已结束</span>
							</div>
							<div class="game-user">
								人数{{item.nums ? item.nums:item.player_info.length }}/{{item.player_info.length}}</div>
							<div class="game-user"> 花费钻石{{item.total_price/item.player_info.length}}</div>
						</div>
						<div class="game-cases">
							<div class="case-img-box is-down case-animation" style="width: 40px;"
								v-for="(item1, index1) in item.boxInfo" :key="index1">
								<img :style="item1.img_main ? '':'display: none'" :src="item1.img_main"
									class="case-img">
								<img :style="item1.img_active ? '':'display: none'" :src="item1.img_active"
									class="item-pic">
							</div>
						</div>
						<div class="game-foot">
							<span>{{item.create_time}}</span>
							<span class="chakan" @click="goLuckyRoom(item.battle_id)" style="cursor: pointer">查看</span>
						</div>
					</div>
				</div>
				<div class="chakangengduo">
					<span @click="moveList()">查看更多</span>
				</div>
			</div>
			<div class="hist-warp" v-else>

				<div class="room-left-hint">
					<!-- <div class="roomleft-num">
						<span @click="goLucky">盲盒对战</span>
						<span>></span>
						<span>参加对战编号 {{ id }}</span>
					</div> -->
					<div class="roomleft-btn">
						<div @click="goLucky"><img src="../assets/img/ranking/zuo.png">返回</div>
						<!-- <span>邀请好友</span> -->
					</div>
				</div>

				<div class="title">
					<span class="title-text">我购买的</span>
					<span class="en-title">MYGAME</span>
				</div>
				<div class="hist-list">

					<div class="bot">
						<el-table :data="tableData1" style="width: 100%" stripe>
							<el-table-column prop="create_time" label="时间">
							</el-table-column>
							<el-table-column label="盲盒信息" width="480">
								<template slot-scope="scope">
									<div class="manghexinxi">
										<div v-for="(item, index) in scope.row.boxInfo" :key="index">
											<img :style="item.img_main ? 'width: 40px; height: 40px':'display: none'" :src="item.img_main"
												class="case-img">
											<img :style="item.img_active ? 'width: 40px; height: 40px':'display: none'" :src="item.img_active"
												class="item-pic">
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column label="花费钻石" width="150">
								<template slot-scope="scope">
									<span class="boxssss"><img src="../assets/img/money.png"
											alt=""><span>{{scope.row.total_price}}</span></span>
								</template>
							</el-table-column>
							<el-table-column label="对战结果" width="150">
								<template slot-scope="scope">
									<span v-html="reResult(scope)"></span>
								</template>
							</el-table-column>
							<el-table-column prop="statusName" label="状态" width="150">
								<template slot-scope="scope">
									<span :class="[`text_${scope.row.status}`]">
										{{scope.row.status == 1 ? '等待中':scope.row.status == 2? '对战中':'已结束'}}
									</span>

								</template>
							</el-table-column>
							<el-table-column label="玩家" width="150">
								<template slot-scope="scope">
									{{scope.row.nums ? scope.row.nums:scope.row.player_info.length }}/{{scope.row.player_info.length}}
								</template>
							</el-table-column>
							<el-table-column label="操作" width="150">
								<template slot-scope="scope">
									<a class="hovera" @click="goLuckyRoom(scope.row.battle_id)"
										style="cursor: pointer">查看</a>
								</template>
							</el-table-column>
						</el-table>
					</div>
					<div class="roll-page">
						<el-pagination background layout="prev, pager, next" :total="totalSize1" :page-size="pageSize1"
							@current-change="currentChange1">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
	</div>
	</div>
</template>

<script>
	import {
		parse
	} from "qs";
	import {
		BattleBox
	} from "@/views/common";
	export default {
		components: {
			BattleBox
		},
		props: ["yidongmenu", "showNav", "openHongbao"],
		data() {
			return {
				activeName: "one",
				totalSize: 0,
				page: 1,
				pageSize: 24,
				tableData: [],
				totalSize1: 0,
				page1: 1,
				pageSize1: 20,
				tableData1: [],
			};
		},
		mounted() {
			this.getMyHist(this.page1);
		},
		methods: {
			moveList() {
				this.page = this.page + 1;
				let param = {
					page: this.page,
					pageSize: this.pageSize1,
				};
				this.$axios
					.post("index/Battle/battleHistory", this.$qs.stringify(param))
					.then((res) => {
						 console.log(res.data.data.list,'iiiiiiiiiiiiiiiiiii');
						var data = res.data;
						if (data.status == "1" && data.data != null) {
							this.totalSize1 = data.data.total;
							let result = data.data.list;
							for (let i = 0; i < result.length; i++) {
								result[i].boxInfo = JSON.parse(result[i].boxInfo);
								result[i].price = 0;
								var info = result[i].boxInfo;
								for (let j = 0; j < info.length; j++) {
									result[i].price += Number(info[j].price);
								}
								result[i].price = result[i].price.toFixed(2);
							}
							//几个人参与
							for (let i = 0; i < result.length; i++) {
								let play = JSON.parse(JSON.stringify(result[i].player_info));
								let mode = result[i].mode;
								for (let j = 0; j < mode - result[i].player_info.length; j++) {
									play.push({
										img: require("../assets/img/jiapeople.png"),
										class: "pk-false",
										id: play.length,
										border: "1px dashed #e9b10e",
									});
								}
								for (let x = 0; x < play.length; x++) {
									if (play[x] && play[x].id) {
										play[x].class = "pk-true";
										play[x].img = play[x].img;
									}
								}
								result[i].player_info = play;
								result[i].player_info.forEach(e => {
									if (!e['name']) {
										if (!result[i]['nums']) {
											result[i]['nums'] = 1;
										} else {
											result[i]['nums'] += 1;
										}
									}
								})

								if (mode == "3") {
									result[i].play1 = [];
									result[i].play2 = [];
									result[i].play1.push(play[0]);
									result[i].play2.push(play[1]);
									result[i].play2.push(play[2]);
								}
								if (mode == "4") {
									result[i].play1 = [];
									result[i].play2 = [];
									result[i].play1.push(play[0]);
									result[i].play1.push(play[1]);
									result[i].play2.push(play[2]);
									result[i].play2.push(play[3]);
								}
							}
							result.forEach(e => {
								this.tableData1.push(e);
							})
							this.$forceUpdate()
							// for (let i = 0; i < this.tableData1.length; i++) {
							//   this.tableData1[i].boxInfo = JSON.parse(
							//     this.tableData1[i].boxInfo
							//   );
							//   if (this.tableData1[i].status == 1) {
							//     this.tableData1[i].statusName = "等待中";
							//   } else if (this.tableData1[i].status == 2) {
							//     this.tableData1[i].statusName = "进行中";
							//   } else {
							//     this.tableData1[i].statusName = "已结束";
							//   }
							// }
						}
					});
			},
			getTab(tab) {
				this.getMyHist(this.page1);
			},
			getMyHist(page) {
				let param = {
					page: page,
					pageSize: this.pageSize1,
				};
				this.$axios
					.post("index/Battle/battleHistory", this.$qs.stringify(param))
					.then((res) => {
						 console.log(res.data.data.list,'pppppppppppppppppp');
						var data = res.data;
						if (data.status == "1" && data.data != null) {
							this.totalSize1 = data.data.total;
							let result = data.data.list;
							for (let i = 0; i < result.length; i++) {
								result[i].boxInfo = JSON.parse(result[i].boxInfo);
								result[i].price = 0;
								var info = result[i].boxInfo;
								for (let j = 0; j < info.length; j++) {
									result[i].price += Number(info[j].price);
								}
								result[i].price = result[i].price.toFixed(2);
							}
							//几个人参与
							// console.log(result)
							for (let i = 0; i < result.length; i++) {
								let play = JSON.parse(JSON.stringify(result[i].player_info));
								let mode = result[i].mode;
								for (let j = 0; j < mode - result[i].player_info.length; j++) {
									play.push({
										img: require("../assets/img/jiapeople.png"),
										class: "pk-false",
										id: play.length,
										border: "1px dashed #e9b10e",
									});
								}
								for (let x = 0; x < play.length; x++) {
									if (play[x] && play[x].id) {
										play[x].class = "pk-true";
										play[x].img = play[x].img;
									}
								}
								result[i].player_info = play;
								result[i].player_info.forEach(e => {
									if (!e['name']) {
										if (!result[i]['nums']) {
											result[i]['nums'] = 1;
										} else {
											result[i]['nums'] += 1;
										}
									}
								})

								if (mode == "3") {
									result[i].play1 = [];
									result[i].play2 = [];
									result[i].play1.push(play[0]);
									result[i].play2.push(play[1]);
									result[i].play2.push(play[2]);
								}
								if (mode == "4") {
									result[i].play1 = [];
									result[i].play2 = [];
									result[i].play1.push(play[0]);
									result[i].play1.push(play[1]);
									result[i].play2.push(play[2]);
									result[i].play2.push(play[3]);
								}
							}
							this.tableData1 = result;
							// for (let i = 0; i < this.tableData1.length; i++) {
							//   this.tableData1[i].boxInfo = JSON.parse(
							//     this.tableData1[i].boxInfo
							//   );
							//   if (this.tableData1[i].status == 1) {
							//     this.tableData1[i].statusName = "等待中";
							//   } else if (this.tableData1[i].status == 2) {
							//     this.tableData1[i].statusName = "进行中";
							//   } else {
							//     this.tableData1[i].statusName = "已结束";
							//   }
							// }
						}
					});
			},
			//分页 所有记录
			currentChange(val) {
				this.getHist(val);
			},
			//分页 我的记录
			currentChange1(val) {
				this.getMyHist(val);
			},
			goLucky() {
				this.$router.push({
					path: `/Lucky`,
				});
			},
			goLuckyRoom(id) {
				// console.log(`/LuckyRoom?id=${id}`);
				this.$router.push({
					path: `/LuckyRoom?id=${id}`,
				});
			},
			// 返回对接结果
			reResult(scope) {
				if (!scope.row.winner) {
					return '<span style="color: #7c7cec;font-size: 16px;">-</span>';
				}
				let winner = scope.row.winner.split(",");
				let me = scope.row.player_id + "";
				// console.log(winner, me);
				// console.log(winner.indexOf(me));
				if (winner.indexOf(me) != -1) {
					return '<span style="color: #8e4ef6;font-size: 16px;">胜利</span>';
				} else {
					return '<span style="color: #a4acc8;font-size: 16px;">失败</span>';
				}
			},
		},
	};
</script>

<style lang="less" scoped>
	@import "./css/luckhistory.less";

	::v-deep {
		.el-tabs__content {
			padding: 0 !important;
		}

		.el-tabs__item.is-active {
			background-color: #e9b10e !important;
			color: #000000 !important;
			font-weight: bold;
		}

		.el-tabs__header {
			margin-bottom: 20px !important;
		}

		.el-tabs__item:not(.is-disabled, .is-active):hover {
			color: #e9b10e !important;
		}
	}

	.hovera {
		color: rgba(64, 158, 255, 1) !important;
	}

	.hovera:hover {
		color: rgba(130, 184, 255, 1.0) !important;
	}

	.manghexinxi {
		display: flex;
		align-content: center;

		div {
			position: relative;

			img {
				width: 50px !important;
				height: 50px !important;
			}

			img:last-child {
				left: 0;
				right: 0;
				top: 0;
				right: 0;
				width: 40px !important;
				height: 40px !important;
				position: absolute;
			}
		}
	}

	.boxssss {
		img {
			width: 20px;
		}

		display: flex;
		align-items: center;
		justify-content: center;

		span {
			font-weight: 600;
			color: #ffc83c;
			margin-left: 4px;
			font-family: '微软雅黑';
		}
	}

	.el-table .el-table__row {
		background: #412492 !important;
	}

	.hist {
		position: relative;
		overflow: hidden;
		overflow-y: auto;
		width: 100%;
		height: 100vh;
		background-size: 100% 100%;
		z-index: 2;
		background-color: #160e39;

		.hist-box {
			background: url('../assets/img/main-bg.png') no-repeat;
			height: 100%;
			background-position: 50% 0%;
			background-size: 95%;
			padding: 0 32px;
			padding-top: 32px;
		}

		.hist-warp {
			width: 100vw;
			max-width: 1440px;
			margin: auto;

			.room-left-hint {
				margin-top: 20px;
				display: flex;
				justify-content: space-between;

				.roomleft-num {
					display: flex;
					align-items: center;
					color: #848492;
					font-size: 15px !important;

					span {
						margin-right: 5px;

						&:last-child:hover {
							color: #e9b10e;
						}
					}
				}

				.roomleft-btn {
					width: 100%;
					margin-right: -5px;
					display: flex;
					align-items: center;
					justify-content: space-between;

					div {
						padding: 6px 15px;
						color: #fff !important;
						font-weight: 400;
						font-size: 14px;
						margin-right: 10px;
						cursor: pointer;
						display: flex;
						align-items: center;
						color: #c9c9f1 !important;

						img {
							width: 32px;
						}
					}

					/deep/.el-switch__label {

						color: #fff !important;
					}

				}
			}

			.title {
				height: 57px;
				font-family: YouSheBiaoTiHei;
				position: relative;
				max-width: 700px;
				width: 100vw;
				margin: 0 auto;
				padding: 50px 0;
			}

			.title::after {
				content: " ";
				display: block;
				position: absolute;
				width: 100%;
				height: 100%;
				background: url('../assets/img/title-bg.png') center no-repeat;
				background-size: auto;
				background-size: 100%;
				z-index: 10;
				top: 0;
			}

			.title .title-text {
				position: absolute;
				top: 52px;
				font-size: 36px;
				z-index: 11;
				left: 50%;
				margin-left: -100px;
				width: 200px;
				text-align: center;
				color: #fff;
			}

			.title .en-title {
				position: absolute;
				top: 20px;
				font-size: 50px;
				color: #392782;
				opacity: .3;
				z-index: 9;
				left: 50%;
				margin-left: -100px;
				width: 200px;
				text-align: center;
			}

		}

		.text_1 {
			color: #fb9a84;
		}

		.text_2 {
			color: #67c23a;
		}

		.text_3 {
			color: #999;
		}

		/deep/.cell {
			text-align: center;
		}

		/deep/th {
			background: #3D2A80 !important;
			color: white;
		}

		//tabs
		.hist-list {
			margin-top: 20px;
		}

		.hist-list /deep/ .el-tabs--border-card {
			//overflow: hidden;
			background-color: #191b23;
			border: none;
			// border-radius: 5px;
		}

		.hist-list /deep/ .el-tabs--border-card>.el-tabs__header {}

		.hist-list /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {}

		.hist-list /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item {}

		.hist-list /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
			color: #e9b10e;
		}

		//页数
		.roll-page {
			padding: 20px 0;
			text-align: center;
		}

		.roll-page /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
			background-color: #e447c1 !important;
			color: #4224bd !important;
			border: 1px solid #4E2CA4 !important;
		}

		.roll-page /deep/ .el-pagination.is-background .btn-next,
		/deep/ .el-pagination.is-background .btn-prev,
		/deep/ .el-pagination.is-background .el-pager li {
			// background-color: #333542;
			// color: #848492;
			border: 1px solid #4E2CA4 !important;
			background: transparent;
			color: #4E2CA4;
		}

		//表格
		.bot /deep/ .el-table tr {
			font-family: '微软雅黑';
			color: white;
			background-color: rgba(29, 19, 71, 1) !important;
		}

		.bot /deep/ .el-table td,
		.bot /deep/ .el-table th .striped {
			border-bottom: none;
		}

		/deep/.el-table th.is-leaf {
			border-bottom: none;
		}

		/deep/.el-table--striped .el-table__body tr.el-table__row--striped td {

			background-color: rgba(38, 25, 87, 1);
		}

		.bot /deep/ .el-table::before {
			height: 0;
		}

		.bot /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td {
			background-color: #412492 !important;
		}

		.bot /deep/ th .cell {
			padding: 0;
			height: 25px;
			line-height: 25px;
		}

		.bot /deep/ .cell {
			padding: 0;
			height: 60px;
			line-height: 60px;
		}

		.bot /deep/ .el-table__empty-block {
			background-color: #2b2c37;
		}

		.roomleft-num {
			display: flex;
			align-items: center;
			color: #848492;
			font-size: 14px;

			span {
				margin-right: 5px;
			}

			span {
				font-size: 14px;
				font-weight: 200;
			}

			span:hover {
				cursor: pointer;
				color: #e9b10e;
				text-decoration: underline;
			}

			span {
				font-weight: 200;
				color: #c3c3e2;
				font-size: 14px;
			}
		}
	}
</style>